import { Injectable } from '@angular/core';
import { FindUsersRolesByCriteriaRequest } from '@app/shared/interfaces/user-roles/find-users-roles-by-criteria.request';
import { IUserRole } from '@shared-global/interfaces/users-roles/interfaces/user-role.interface';
import { AbstractCrudService } from '../../abstract/abstract-crud.service';

@Injectable()
export class UsersRolesService extends AbstractCrudService<
  IUserRole,
  FindUsersRolesByCriteriaRequest
> {
  protected override feature = 'users-roles';
  protected override endPoint = `/users-roles/`;
}
